body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Arial", "Roboto", "Segoe UI";
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.container {
  display: flex;
  /* min-height: calc(100vh - 40px); */
  min-height: 100vh;
  flex-direction: column;
  /* background-color: "#2F4F4F";
  color: "#eeeeee"; */
}

.nav {
  height: 5px;
  /* padding: 5px; */
  /* background: lightblue; */
}

.main {
  /* padding: 20px; */
  /* background: purple; */
  flex: 1;
}

.footer {
  /* background: magenta; */
  /* padding: 10px; */
  /* height: 50px; */
  height: 20vh;
}
